.header {
  text-align: center;
  background: #63CEA4;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 0px;
}

.welcomeMargin {
  margin-top: 5rem;
}

.bodyMargin {
  margin-top: 10rem;
}

/* From http://tobiasahlin.com/spinkit/ */
.spinner {
  width: 40px;
  height: 40px;
  background-color: #63CEA4;
  margin: 0px 0px 15px 0px;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.App {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
  background: #ededed;
  height: 100vh;
  width: 100%;

  &>div {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
}

.loader {
  display: flex;
}

.infoMsg {
  background: #c9e4d7;
  padding: 30px 20px;
  margin: 30px 0;
  border-radius: 10px;

  span {
    color: #3c9673;
    font-weight: bold;
  }

  h3 {
    font-size: medium;
  }

  b {
    display: block;
  }
}

//Bottstrap overwrite
.btnPrimary {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #ffffff !important;
  background-color: #63CEA4 !important;
  border-color: #63CEA4 !important;
}


h1,
p {
  color: black;
}

@import '~react-toastify/dist/ReactToastify.css';